import React, { Component } from 'react';
import Card from 'components/Card.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, Row, Col } from 'react-bootstrap';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Table, Breadcrumb, notification, Tag } from 'antd';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { withTranslation } from 'react-i18next';

import { getCenterEntitiesByCenter } from 'util/APIUtils';
import { deleteCenterEntity } from 'repository/centerEntity/CenterEntityRepository';

import moment from 'moment';
import 'moment/locale/es';
import { MdAdd } from 'react-icons/md';
import { HiOfficeBuilding } from 'react-icons/hi';
import { TbTrashXFilled } from 'react-icons/tb';
import ButtonLink from 'components/Buttons/ButtonLink';
moment.locale('es');

class CenterEntities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      centername: localStorage.getItem('center'),
      data: [],
      totalCenterEntities: 0,
    };

    this.columns = [
      {
        title: this.props.t('center-entities.list.table.name-title'),
        dataIndex: 'name',
        key: 'name',
        align: 'center',
      },
      {
        title: this.props.t('center-entities.list.table.opposite-direction-title'),
        dataIndex: 'canFineOppositeDirection',
        align: 'center',
        render: (text, record) => {
          if (record.canFineOppositeDirection === true) return <Tag color="green">SÍ</Tag>;
          else return <Tag color="red">NO</Tag>;
        },
      },
      {
        title: this.props.t('center-entities.list.table.actions-title'),
        dataIndex: 'acciones',
        width: '150px',
        align: 'center',
        render: (text, record) => {
          return (
            <ButtonLink
              onClick={() => {
                this.warningWithConfirmAndCancelMessage(record.name);
              }}
              color="black"
            >
              <TbTrashXFilled style={{ fontSize: '30' }} />
            </ButtonLink>
          );
        },
      },
    ];

    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);
  }

  componentDidMount() {
    this.updateTable();
  }

  updateTable() {
    this.setState({ loading: true });

    getCenterEntitiesByCenter(this.state.centername)
      .then((response) => {
        this.setState({ data: response, loading: false, totalCenterEntities: response.length });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  warningWithConfirmAndCancelMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('center-entities.list.modal.warning-message')}
          onConfirm={() => this.successDelete(id)}
          onCancel={() => this.cancelDelete()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText={this.props.t('center-entities.list.modal.warning-confirm')}
          cancelBtnText={this.props.t('center-entities.list.modal.warning-cancel')}
          showCancel
        />
      ),
    });
  }

  successDelete(id) {
    this.setState({ loading: true });
    deleteCenterEntity(this.state.centername, id)
      .then((response) => {
        notification['success']({
          message: 'ZinkinData Portal',
          description: this.props.t('center-entities.list.modal.success-deletion'),
        });
        this.hideAlert();
        this.updateTable();
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.hideAlert();
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
          duration: 7,
        });
        this.setState({ loading: false });
      });
  }

  cancelDelete() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block' }}
          title={this.props.t('center-entities.list.modal.success-cancel-title')}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.props.t('center-entities.list.modal.success-cancel-message')}
        </SweetAlert>
      ),
    });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  render() {
    if (this.props.currentUser.role !== 'ROLE_SUPERADMIN' && this.props.currentUser.role !== 'ROLE_ADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      return (
        <div className="main-content">
          {this.state.alert}
          <Grid fluid>
            <Breadcrumb
              separator=">"
              style={{ marginBottom: '10px', fontSize: 20 }}
            >
              <Breadcrumb.Item>
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i style={{ lineHeight: '30px', verticalAlign: 'middle', marginRight: '5px', fontSize: '30px' }}>
                    <HiOfficeBuilding />
                  </i>
                  {this.props.t('center-entities.common-breadcrumb')} <b>({this.state.totalCenterEntities})</b>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Card
                    content={
                      <div>
                        <ButtonPrimary
                          color="blue"
                          shape="round"
                          size="large"
                          style={{
                            margin: '10px 0 20px 0',
                          }}
                          onClick={() => this.props.history.push('/create-center-entities')}
                        >
                          {this.props.t('center-entities.list.create-entity-button')}
                          <MdAdd
                            style={{
                              verticalAlign: 'sub',
                              fontSize: '20',
                              marginLeft: '5px',
                            }}
                          />
                        </ButtonPrimary>

                        <Table
                          dataSource={this.state.data}
                          columns={this.columns}
                          pagination={{ pageSize: 10 }}
                        />
                      </div>
                    }
                  />
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(CenterEntities);
