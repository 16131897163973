// Matriculas
import LicensePlates from 'views/LicensePlates/citizens/LicensePlates';
import SpecialLicensePlates from 'views/SpecialLicensePlates/SpecialLicensePlates';
import SpecialLicensePlatesCreate from 'views/SpecialLicensePlates/SpecialLicensePlatesCreate';

// Profile
import Profile from 'views/Profile/Profile';

// Admin User List
import AdminUserList from 'views/AdminUsers/AdminUserList';
import AdminUserCreate from 'views/AdminUsers/AdminUserCreate';
import AdminUserEdit from 'views/AdminUsers/AdminUserEdit';

// Users (Ciutadans)
import UserList from 'views/Citizens/UserList';
import UserEdit from 'views/Citizens/UserEdit';
import UserCreate from 'views/Citizens/UserCreate';

// Matriculas
import AdminLicensePlates from 'views/LicensePlates/admins/AdminLicensePlates';
import AdminLicensePlatesCreate from 'views/LicensePlates/admins/AdminLicensePlatesCreate';

// ZoneAuthoritzations
import ZoneAuthoritzationsList from 'views/ZoneAuthoritzations/admins/ZoneAuthoritzationsList';
import ZoneAuthoritzationsCreate from '../views/ZoneAuthoritzations/admins/ZoneAuthoritzationsCreate';
import CitizenZoneAuthoritzationsList from 'views/ZoneAuthoritzations/citizens/CitizenZoneAuthoritzationsList';

// Center Entities
import CenterEntities from './../views/CenterEntities/CenterEntities';
import CreateCenterEntities from './../views/CenterEntities/CreateCenterEntities';

// Audit Logs
import AuditLogs from '../views/AuditLogs/AuditLogsList';

import {
  MdAccountCircle,
  MdPeople,
  MdFireTruck,
  MdDirectionsCarFilled,
  MdLocationOn,
  MdManageSearch,
} from 'react-icons/md';
import { RiShieldUserLine } from 'react-icons/ri';
import { HiOfficeBuilding } from 'react-icons/hi';
import LicensePlatesCreate from 'views/LicensePlates/citizens/LicensePlatesCreate';

var dashboardRoutes = [
  {
    path: '/profile',
    name: 'sidebar.profile',
    icon: MdAccountCircle,
    component: Profile,
    roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPERADMIN'],
  },
  {
    path: '/license-plates',
    name: 'sidebar.license-plate',
    icon: MdDirectionsCarFilled,
    component: LicensePlates,
    roles: ['ROLE_USER'],
  },
  {
    hidden: true,
    path: '/license-plates-add',
    name: 'sidebar.admin-add-plates',
    component: LicensePlatesCreate,
  },
  {
    path: '/users',
    name: 'sidebar.citizen',
    icon: MdPeople,
    component: UserList,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
  },
  {
    hidden: true,
    path: '/users-add',
    name: '',
    component: UserCreate,
  },
  {
    hidden: true,
    path: '/users-edit',
    name: '',
    component: UserEdit,
  },
  {
    path: '/admin-users',
    name: 'sidebar.admin-users',
    icon: RiShieldUserLine,
    component: AdminUserList,
    roles: ['ROLE_SUPERADMIN'],
  },
  {
    hidden: true,
    path: '/admin-users-add',
    component: AdminUserCreate,
  },
  {
    hidden: true,
    path: '/admin-users-edit',
    component: AdminUserEdit,
  },
  {
    hidden: true,
    path: '/admin-license-plates',
    component: AdminLicensePlates,
  },
  {
    hidden: true,
    path: '/admin-license-plates-add',
    component: AdminLicensePlatesCreate,
  },
  {
    hidden: true,
    path: '/authoritzations',
    component: ZoneAuthoritzationsList,
  },
  {
    path: '/citizen-authoritzations',
    name: 'sidebar.zone-authorizations',
    icon: MdLocationOn,
    component: CitizenZoneAuthoritzationsList,
    roles: ['ROLE_USER'],
  },
  {
    hidden: true,
    path: '/authoritzations-create',
    component: ZoneAuthoritzationsCreate,
  },
  {
    path: '/center-entities',
    name: 'sidebar.center-entities',
    icon: HiOfficeBuilding,
    component: CenterEntities,
    roles: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
  },
  {
    hidden: true,
    path: '/create-center-entities',
    component: CreateCenterEntities,
  },
  {
    path: '/special-license-plates',
    name: 'sidebar.special-license-plates',
    icon: MdFireTruck,
    component: SpecialLicensePlates,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
  },
  {
    path: '/audit-logs',
    name: 'sidebar.audit-logs',
    icon: MdManageSearch,
    component: AuditLogs,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
  },
  {
    hidden: true,
    path: '/special-license-plates-add',
    component: SpecialLicensePlatesCreate,
  },
];
export default dashboardRoutes;
