import { API_BASE_URL, buildOptionsWithAuth } from 'util/Constants.js';

export function deleteCenterEntity(center, id) {
  const url = `${API_BASE_URL}/admin/${center}/center-entities/${id}`;
  const headers = buildOptionsWithAuth('DELETE');

  return fetch(url, headers)
    .then(async (response) => {
      if (response.status === 422) {
        throw new Error('Parametres erronis');
      }
      if (response.status === 409) {
        throw new Error('No es pot borrar una entitat que contingui matricules especials asociades');
      }
      if (response.status === 404) {
        throw new Error('Entitat/Departament no trobat');
      }
      if (response.status === 500) {
        throw new Error('Error intern de servidor');
      }
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}

export function postCenterEntity(centerEntityName, centerName, canFineOppositeDirection) {
  const url = `${API_BASE_URL}/admin/center-entities?center-entity-name=${centerEntityName}&center-name=${centerName}&can-fine-opposite-direction=${canFineOppositeDirection}`;
  const headers = buildOptionsWithAuth('POST');

  return fetch(url, headers).then((response) => {
    if (response.status !== 201) {
      if (response.status === 409) throw new Error('Ja existeix una entitat amb aquest nom');
      else throw new Error('Error intern de servidor');
    }
  });
}
